.app-regenerate-modal {
	min-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	@include max-width($scr-sm) {
		padding: 10px;
	}

	.regenerate-modal {
		padding: 20px 40px;
		width: 100%;
		max-width: 588px;
		border-radius: 8px;
		background-color: $c-white;
		position: relative;

		@include max-width($scr-sm) {
			padding: 20px;
		}

		&__container {
			display: flex;
			align-items: center;
			flex-direction: column;

			.app-button {
				margin: 30px;
			}
		}

		&__form {
			width: 100%;
			padding: 0;
			gap: 20px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			.app-input,
			.app-select-input {
				@include min-width($scr-sm) {
					width: calc(50% - 10px);
				}
			}
		}

		&__button-container {
			width: 100%;
			padding: 20px 0 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				width: auto;
				margin: 0 10px;
				padding: 10px 20px;

				&--outline {
					border: 0;

					.app-button__label {
						color: $c-teal;
					}
				}
			}
		}

		&__title {
			margin: 0;
			text-align: center;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__text {
			margin: 8px 0 0 0;
			text-align: center;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
		}
	}
}
